///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

//メイン見出し h2
.c-ttl2 {
  color: $keyColor;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 16px;
  @include mq-max(xs) {
    font-size: 24px;
  }
  &--black {
    color: $black;
    text-align: center;
  }
}

//メイン見出し h3
.c-ttl3 {
  color: $keyColor;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 6px;
  @include mq-max(xs) {
    font-size: 18px;
  }
}

//吹き出し
.c-fukidashi {
  position: relative;
  margin-bottom: 21px;
  text-align: center;
  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 170px;
    background: $keyColor;
    margin:15px auto 0;
  }
  span {
    &:before {
      content: "";
      position: absolute;
      bottom: -17px;
      left: 50%;
      margin-left: -15px;
      border: 10px solid transparent;
      border-top: 10px solid $white;
      z-index: 2;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -22px;
      left: 50%;
      margin-left: -17px;
      border: 12px solid transparent;
      border-top: 12px solid $keyColor;
      z-index: 1;
    }
  }
}