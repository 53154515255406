//コンバージョンエリア

.c-cvArea {
  padding: 60px 0 80px;
  background-color: $keyColor;
  overflow: hidden;
  @include mq-max(xs) {
    padding: 40px 0;
  }
  &__inner {
    max-width: $contentWidth-wide;
    margin: 0 auto;
  }

  &__heading {
    text-align: center;
    color: #fff;
    font-size: 32px;
    margin-bottom: 55px;
    @include mq-max(md) {
      font-size: 26px;
      padding: 0 10px;
    }
    @include mq-max(sm) {
      font-size: 24px;
    }
    @include mq-max(xs) {
      font-size: 22px;
    }
    &--sub {
      display: block;
      margin-bottom: 8px;
    }
    &--top {
      display: block;
      font-size: 16px;
      margin-bottom: -5px;
      @include mq-max(xs) {
        font-size: 14px;
      }
    }
    &--bottom {
      display: block;
      font-size: 24px;
      @include mq-max(xs) {
        font-size: 20px;
      }
    }
  }

  &__btns {
    @include mq-max(bp1130) {
      padding: 0 5px;
      margin-left: 0;
      margin-right: 0;
    }
    @include mq-max(md) {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
    }
    &>li {
      @include mq-max(md) {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include mq-max(xs) {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__btn {
    background-color: #fff;
    display: block;
    text-align: left;
    color: $textColor !important;
    padding: 34px 50px 34px 150px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 7px;
    position: relative;
    box-shadow: $box-shadow;
    transition: all $transition !important;
    @include mq-max(bp1130) {
      font-size: 18px;
    }
    @include mq-max(xs) {
      font-size: 16px;
      padding: 25px 10px 15px 10px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:before {
      margin-right: 7px;
    }
    &:after {
      @include fa('f105');
      font-size: 26px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      transition: all $transition;
      @include mq-max(xs) {
        display: none;
      }
    }
    &:hover {
      box-shadow: 0 0 0 rgba(0,0,0,0);
      &:after {
        right: 5px;
      }
    }
    &--present {
      padding-left: 175px;
      @include mq-max(md) {
        padding-left: 180px;
      }
      @include mq-max(xs) {
        padding-top: 18px !important;
        padding-bottom: 11px !important;
      }
      &:before {
        content: '';
        width: 108px;
        height: 81px;
        background: url('/inc/image/common/pic_cv-present.png')no-repeat center / contain;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        @include mq-max(md) {
          width: 90px;
          height: 51px;
        }
        @include mq-max(xs) {
          width: 58px;
          height: 44px;
          position: relative;
          display: inline-block;
          top: 0;
          left: 0;
          transform: none;
        }
      }
    }
    &--demo {
      padding-left: 145px;
      @include mq-max(md) {
        padding-left: 155px;
      }
      &:before {
        content: '';
        width: 102px;
        height: 64px;
        background: url('/inc/image/common/pic_cv-demo.png')no-repeat center / contain;
        position: absolute;
        top: 50%;
        left: 13px;
        transform: translateY(-50%);
        @include mq-max(md) {
          width: 90px;
          height: 48px;
        }
        @include mq-max(xs) {
          width: 51px;
          height: 33px;
          position: relative;
          display: inline-block;
          top: 0;
          left: 0;
          transform: none;
        }
      }
    }
    &-sub {
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
      color: $keyColor;
      background-color: #fff;
      padding: 6px 40px;
      border-radius: 20px;
      box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.16);
      position: absolute;
      top: -17px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
}