.pageTop{
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 95;
  @include mq-max(xs) {
    bottom: 70px;
  }
  &:after {
    @include fa('f077');
    display: inline-block;
    color: #fff;
    padding: 20px;
    background-color: $keyColor;
    border-radius: 50%;
    line-height: 1;
  }
}
