
.c-box {
  background: $white;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: 30px 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0
  }
}

//コンテンツボックス
.c-contentBox {
  max-width: $contentWidth;
  margin: 0 auto 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &--wide {
    max-width: $contentWidth-wide;
  }
  &--small {
    max-width: $contentWidth-small;
  }
}

//FAQボックス
@mixin faqIcon($char) {
  content: $char;
  display: block;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  color: $keyColor;
  position: absolute;
  left: 30px;
  @if $char == 'Q' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $char == 'A' {
    top: 11px;
  }
}
.c-faqBox {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &__q {
    font-size: 18px;
    font-weight: 600;
    background-color: $lightGreen;
    color: $keyColor;
    padding: 14px 30px 14px 74px;
    position: relative;
    border: 1px solid $keyColor;
    border-radius: 10px 10px 0 0;
    &:before {
      @include faqIcon('Q');
    }
  }
  &__a {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 60px 10px 74px;
    position: relative;
    line-height: 2;
    border: 1px solid $keyColor;
    border-top: none;
    border-radius: 0 0 10px 10px;
    @include mq-max(xs) {
      padding-right: 30px;
    }
    &:before {
      @include faqIcon('A');
    }
  }
  &__em {
    font-size: inherit;
    display: block;
  }
}