// 便利CSS集

.u {
  //5の倍数で作成
  @for $i from 0 through 20 {
    &-pt#{5 * $i} {
      padding-top: #{$i*5}px;
    }
    &-pb#{5 * $i} {
      padding-bottom: #{$i*5}px;
    }
    &-mt#{5 * $i} {
      margin-top: #{$i*5}px;
    }
    &-mb#{5 * $i} {
      margin-bottom: #{$i*5}px;
    }
  }
}
