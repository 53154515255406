
//パーツ（フッター）

//電話番号の境界線
%tel_divide {
  &:before,
  &:after {
    content: '';
    width: 1px;
    height: 42px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include mq-max(sm) {
      display: none;
    }
  }
  &:before {
    left: 0;
  }
  &:after {
    display: none;
    right: 0;
  }
}


//フッター
.l-f {
  border-bottom: 11px solid $keyColor;
  &__tel {
    background-color: $accentColor;
    color: #fff;
    &-inner {
      max-width: $contentWidth-small;
      margin: 0 auto;
      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq-max(sm) {
        padding: 10px 10px 20px;
      }
      @include mq-max(xs) {
        flex-direction: column;
        align-items: center;
        padding: 10px 10px 15px;
      }
      &>li {
        font-size: 16px;
        padding: 0 30px;
        position: relative;
        @include mq-max(sm) {
          padding: 0 3%;
        }
        @extend %tel_divide;
        &:last-child {
          @extend %tel_divide;
          &:after {
            display: block;
            @include mq-max(sm) {
              display: none;
            }
          }
        }
      }
    }
    &-num {
      font-size: 40px;
      @include mq-max(sm) {
        font-size: 34px;
      }
      &>a {
        color: inherit;
        &:hover {
          color: inherit;
        }
      }
    }
    &-term {
      font-size: 16px;
      margin-left: 6px;
      @include mq-max(sm) {
        display: block;
      }
      @include mq-max(xs) {
        text-align: center;
      }
    }
  }

  &__inner {
    max-width: $contentWidth-wide;
    margin: 0 auto;
    padding: 65px 0 30px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include mq-max(md) {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 60px;
    }
    @include mq-max(bp900) {
      flex-direction: column;
      align-items: center;
      padding: 30px 15px 60px;
    }
  }

  &__logo {
    width: 296px;
    @include mq-max(bp900) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    @include mq-max(bp900) {
      flex-wrap: wrap;
      justify-content: center;
    }
    &>li {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      &>a {
        color: $keyColor;
      }
    }
  }

  //Copyright
  &__copyright {
    color: $keyColor;
    font-size: 12px;
    font-weight: 600;
    @include mq-max(bp900) {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      position: relative;
      // bottom: -30px;
    }
    @include mq-max(xs) {
      // bottom: -70px;
    }
  }
}
