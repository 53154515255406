.l-navigation {
//  background-color: $white;
  @include mq-max(md) {
    display: none;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active {
    transform: translateY(0%);
  }
  &__inner {
    padding: 0;
    display: flex;
    @include mq-max(md) {
      display: block;
      height: calc(100vh - #{$headerHeight-sp});
      overflow-y: scroll;
      background-color: rgba($keyColor,.9);
      padding-top: 30px;
    }
  }
  &__item {
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    @include mq-max(md) {
      margin-right: 0;
      // border-top: 1px solid $gray;
      &:last-child{
        // border-bottom: 1px solid $gray;
      }
    }
    a {
      font-size: 14px;
      font-weight: 600;
      @include mq-max(md) {
        background-color: transparent;
      }
    }
    &>a {
      text-decoration: none;
      color: $textColor;
      display: inline-block;
      padding: 29.5px 20px;
      @include mq-max(bp1390) {
        padding: 29.5px 15px;
      }
      @include mq-max(bp1130) {
        padding: 29.5px 12px;
      }
      @include mq-max(md) {
        font-size: 24px;
        color: #fff;
        display: block;
        padding: 5px 15px 5px 33px;
        position: relative;
        &:before {
          @include fa('f105');
          font-size: 16px;
          position: absolute;
          top: 52%;
          left: 20px;
          transform: translateY(-50%);
        }
      }
      &:hover {
        color: $keyColor;
        @include mq-max(md) {
          color: #fff;
        }
        &>span {
          &:before {
            width: 100%;
          }
        }
      }
      &>span {
        display: inline-block;
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          width: 0%;
          height: 2px;
          background-color: $keyColor;
          position: absolute;
          bottom: -4px;
          left: 0;
          transition: all $transition;
        }
      }
      &.current {
        color: $keyColor;
        @include mq-max(md) {
          color: #fff;
        }
        &>span {
          &:before {
            width: 100%;
            @include mq-max(md) {
              display: none;
            }
          }
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a{
        position: relative;
        @include mq-max(md) {
          pointer-events: none;
        }
        &:after {
          @include fa('f107');
          position: absolute;
          top: 52%;
          right: 2px;
          transform: translateY(-50%);
          @include mq-max(bp1390) {
            right: 0;
          }
          @include mq-max(md) {
            @include fa('f067');
            top: 50%;
            right: 16px;
          }
        }
        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }
      }
      &:hover {
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          @include mq-max(md) {
            top: auto;
          }
          a {
            transition: color $transition,
                        height $transition;
            &:hover {
              color: #fff;
              background-color: $keyColor-hover;
            }
          }
        }
      }
      .l-dropDown {
        position: absolute;
        top: $headerHeight - 30px; // headerの高さ - 30px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 8px 14px 8px;
          border-color: transparent transparent $keyColor transparent;
          position: absolute;
          top: -14px;
          left: 46px;
          @include mq-max(md) {
            display: none;
          }
        }
        @include mq-max(md) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
        li {
          overflow: hidden;
          &:first-child {
            border-radius: 7px 7px 0 0;
            @include mq-max(md) {
              border-radius: 0;
            }
          }
          &:last-child {
            border-radius: 0 0 7px 7px;
            @include mq-max(md) {
              border-radius: 0;
            }
          }
          a {
            background-color: $keyColor;
            padding: 10px 15px;
            font-size: 15px;
            font-weight: 600;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: #fff;
            width: 100%;
            @include mq-max(md) {
              background-color: transparent;
              font-size: 20px;
              line-height: 0;
              height: 0;
              padding: 0;
              opacity: 0;
              transition: height $transition,
                          opacity 0.4s,
                          padding $transition,
                          line-height $transition;
            }
          }
        }
        &__sp {
          display: none;
          @include mq-max(md) {
            display: block;
          }
        }
      }
      &.is-opened {
        > .l-dropDown {
          @include mq-max(md) {
            opacity: 1;
            display: block;
            > li {
              // border-bottom: 1px solid $gray;
              // &:first-child {
              //   border-top: 1px solid $gray;
              // }
              // &:last-child {
              //   border-bottom: none;
              // }
            }
            > li > a {
              opacity: 1;
              line-height: 1.5;
              height: 54px;
              padding: 15px 10px 15px 25px;
              @include mq-max(md) {
                height: 45px;
                padding: 7.5px 10px 7.5px 55px;
              }
            }
          }
        }
      }
    }
  }


  &__info {
    color: $keyColor;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 14.5px 0 14.5px 20px;
    padding: 0 0 0 30px;
    box-sizing: border-box;
    border-left: 1px solid $keyColor;
    @include mq-max(bp1300) {
      display: none;
    }
  }
  &__tel {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    &:before {
      content: '';
      width: 13px;
      height: 19px;
      display: block;
      background: url('/inc/image/common/ico_tel.png')no-repeat center / contain;
      position: absolute;
      top: 8px;
      left: -15px;
    }
  }
  &__term {
    font-size: 12px;
    font-weight: 600;
  }

  //資料請求・お問い合わせボタン
  &__btn {
    @include mq-max(md) {
      width: 45%;
      height: 145px;
    }
    @include mq-max(xs) {
      width: 153px;
    }
    &>a {
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      display: block;
      text-align: left;
      width: 149px;
      transition: all $transition;
      position: relative;
      @include mq-max(md) {
        background-color: #fff !important;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 16px;
        border-radius: 10px;
      }
      &:before {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include mq-max(md) {
          position: static;
          transform: none;
          margin: 0 auto;
        }
      }
      &:hover {
        color: #fff;
      }
      @include mq-max(md) {
        width: 100%;
      }
    }
    &-wrap {
      display: flex;
      margin-left: 15px;
      @include mq-max(bp1130) {
        margin-left: 0;
      }
      @include mq-max(md) {
        padding: 30px 0;
        justify-content: center;
      }
    }
    &--doc {
      @include mq-max(md) {
        margin-right: 15px;
      }
      &>a {
        background-color: $keyColor;
        padding: 29px 5px 29px 57px;
        @include mq-max(md) {
          color: $keyColor;
          padding: 19px 20px 14px;
        }
        &:before {
          content: '';
          background: url('/inc/image/common/ico_doc.png')no-repeat center / contain;
          width: 23.5px;
          height: 28px;
          left: 29.2px;
          @include mq-max(md) {
            background: url('/inc/image/common/ico_doc-sp.png')no-repeat center / contain;
            width: 68px;
            height: 81px;
            margin-bottom: 8px;
          }
        }
        &:hover {
          background-color: $keyColor-hover;
          @include mq-max(md) {
            color: $keyColor;
          }
        }
      }
    }
    &--mail {
      @include mq-max(md) {
        margin-left: 15px;
      }
      &>a {
        background-color: $accentColor;
        padding: 29px 5px 29px 42px;
        @include mq-max(md) {
          color: $accentColor;
          padding: 40px 20px 14px;
        }
        &:before {
          content: '';
          background: url('/inc/image/common/ico_mail.png')no-repeat center / contain;
          width: 22.5px;
          height: 26px;
          left: 15.2px;
          @include mq-max(md) {
            background: url('/inc/image/common/ico_mail-sp.png')no-repeat center / contain;
            width: 61px;
            height: 48px;
            margin-bottom: 20px;
          }
        }
        &:hover {
          background-color: $accentColor-hover;
          @include mq-max(md) {
            color: $accentColor;
          }
        }
      }
    }
  }

}
