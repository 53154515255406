// タブレット以下の時に表示する追従ボタンエリア

.c-follow {
  display: none;
  @include mq-max(md) {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 99;
    &__list {
      display: flex;
      justify-content: center;
    }
    &__item {
      background-color: $keyColor;
      margin-right: 10px;
      border-radius: 10px 10px 0 0;
      box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
      max-width: 200px;
      width: 100%;
      @include mq-max(xs) {
        max-width: 163px;
      }
      &:last-child {
        margin-right: 0;
      }
      &>a {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        padding: 13px 32px;
        display: block;
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq-max(xs) {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
        &:before {
          content: '';
          display: inline-block;
          margin-right: 6px;
        }
      }
      &--doc {
        &>a {
          &:before {
            background: url('/inc/image/common/ico_doc.png')no-repeat center / contain;
            width: 23.5px;
            height: 28px;
          }
        }
      }
      &--mail {
        background-color: $accentColor;
        &>a {
          padding: 16px 32px 10px 32px;
          &:before {
            background: url('/inc/image/common/ico_mail.png')no-repeat center / contain;
            width: 21.5px;
            height: 17px;
          }
        }
      }
    }
  }
}