//モーダルパーツ
.c-modal {
  &__popup {
    color: $orange !important;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    padding-left: 1.8em;
    &:before {
      // @include fa('f059');
      // color: $keyColor;
      content: '?';
      color: #fff;
      font-family: $sanserif;
      background-color: $keyColor;
      position: absolute;
      top: 1px;
      left: 0;
      font-size: 19px;
      line-height: 1;
      padding: 3px 7.1px;
      border-radius: 50%;
    }
    &>span {
      text-decoration: underline;
    }
  }
  &__box {
    max-width: 800px;
    background-color: #fff;
    margin: 0 auto;
    padding: 60px 30px;
    border-radius: 10px;
    position: relative;
    @include mq-max(sm) {
      padding: 40px 20px;
      margin: 0 20px;
    }
  }
  &__inner {
    align-items: center;
  }

  &__heading {
    font-size: 24px;
    color: $orange;
    margin-bottom: 6px;
  }
  &__txt {
    font-size: 15px;
  }

  &__close {
    width: 80px;
    height: 80px;
    background-color: $keyColor;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -25px;
    right: -30px;
    @include mq-max(sm) {
      right: -20px;
    }
    &:before {
      content: '';
      background: url('/inc/image/common/ico_cross.png')no-repeat center / contain;
      width: 27px;
      height: 27px;
      display: block;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    &>span {
      display: block;
      width: 100%;
      color: #fff;
      font-size: 15px;
      position: absolute;
      bottom: 9px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}