///////////////////////////////////////////////////////////////////////////////////////////////////////

//  リンク

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-link {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-left: 22px;
  color: $keyColor;
  transition: color $transition !important;
  &:before {
    @include fa('f105');
    font-size: 24px;
    position: absolute;
    top: 54%;
    left: 0;
    transform: translateY(-50%);
    transition: left $transition !important;
  }
  &:hover {
    color: $keyColor-hover;
    &:before {
      left: 5px;
    }
  }

  &[target="_blank"],
  &[href$=".pdf"] {
    padding-left: 0;
    text-decoration: underline;
    &:before {
      content: none;
    }
  }
  // &[target="_blank"] {}
  // &[href$=".pdf"] {}
}